/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').directive('rankingometer',function() {
    return {
        restrict: 'A',
        scope: true,
        link: function(scope, element, attrs) {
            attrs.$observe('rankingometer', function(position){
                var	ctx = element[0].getContext('2d'),
                	grd = ctx.createLinearGradient(0,0,0,170),
                	meterPosition = position * 133 + 18,
                    circle = function(x,y,r,color) {
                        ctx.beginPath();
                        ctx.fillStyle = color;
                        ctx.arc(x,y,r,0,2*Math.PI);
                        ctx.fill();
                    };
                if(!position) {
                    meterPosition = 133 + 18;
                }
                //background
                circle(15,15,15, attrs.rankingometerBg);
                circle(15,154,15, attrs.rankingometerBg);
                ctx.fillRect(0,15,30,140);

                //stripes on bg
                ctx.fillStyle = attrs.rankingometerStripes;
                ctx.fillRect(0,61,30,1);
                ctx.fillRect(0,107,30,1);

                //bg inside
                grd.addColorStop(0.1,'#44ba27');
                grd.addColorStop(0.5,'#ffc600');
                grd.addColorStop(0.9,'#FF0000');
                circle(15,18,2,grd);
                circle(15,152,2,grd);
                ctx.fillRect(13,19,4,132);

                //shadow of marker
                ctx.beginPath();
                ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
                ctx.arc(15,meterPosition + 1,9,0,Math.PI*2, false); // outer (filled)
                ctx.arc(15,meterPosition + 1,2.5,0,Math.PI*2, true); // inner (unfills it)
                ctx.fill();

                //marker
                ctx.beginPath();
                ctx.fillStyle = 'white';
                ctx.arc(15,meterPosition,9,0,Math.PI*2, false); // outer (filled)
                ctx.arc(15,meterPosition,3,0,Math.PI*2, true); // inner (unfills it)
                ctx.fill();
            });
        }
    };
});
