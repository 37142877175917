/*** IMPORTS FROM imports-loader ***/


import popperjs from 'popper.js';
import $ from "jquery";

angular.module('adhesiveApp')
    .directive('popoverTemplate', ['utils', '$compile', '$document', '$controller',
        function (utils, $compile, $document, $controller) {

        var templateDom;

        return {
            restrict: 'A',
            scope: true,
            link: function postLink($scope, element, attr) {
                utils.getTemplate(attr.popoverTemplate, function(content){

                    $scope.togglePopover = function(){
                        if(!element.hasClass('arrow-active')){
                            $scope.$broadcast('popover-opened');
                            if($scope.$parent){
                                $scope.$parent.$emit('popover-opened');
                            }
                        }
                        element.toggleClass('arrow-active');
                        element.popover('toggle');
                    }

                    $scope.closePopover = function(){
                        if(element.hasClass('arrow-active')){
                            element.removeClass('arrow-active');
                            element.popover('toggle');
                        }
                    }

                    var documentClosePopover = function(e){
                        if(element[0] !== e.target &&
                            !element[0].contains(e.target) &&
                            !$(e.target).parents('.popover').length){

                            $scope.closePopover();
                        }
                    };

                    if(attr.popoverCtrl){
                        $controller(attr.popoverCtrl, {
                            $scope: $scope
                        });
                    }

                    var popoverConfig = {
                        html: true,
                        animation: false,
                        placement: 'bottom',
                        trigger: 'manual',
                        content: function(){
                            return $scope.$apply(function(){
                                if(templateDom){
                                    templateDom.remove();
                                }
                                templateDom = $compile(content)($scope);
                                return templateDom;
                            });
                        }
                    };

                    if(attr.popoverContainer){
                        popoverConfig['container'] = attr.popoverContainer;
                    }

                    $(element).popover(popoverConfig);

                    if(!attr.popoverAutoClosingElement) {
                        $document.on('click', documentClosePopover);
                    } else {
                        $document.on('click', function(e) {
                            if($(e.target).parents(attr.popoverAutoClosingElement).length) {
                                documentClosePopover(e);
                            }
                        });
                    }
                    element.on('click', $scope.togglePopover);

                    $scope.$on('$destroy', function(){
                        $document.off('click', documentClosePopover);
                        element.off('click', $scope.togglePopover);
                        templateDom && templateDom.remove();
                    });
                });
            }
        };
    }]);

