/*** IMPORTS FROM imports-loader ***/


angular.module('adhesiveApp').directive('routeLink', ['$location', function($location){
    return {
        restrict: 'A',
        scope: {},
        link: function($scope, element, attr){
            function updateState(){
                var path = '#' + $location.path(),
                    href = attr.href || attr.ngHref;

                if(path == href){
                    element.addClass('active');
                }
                else {
                    element.removeClass('active');
                }
            }

            updateState();
            $scope.$on('$locationChangeSuccess', updateState);
        }
    }
}]);

