/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .directive('piggyList', function () {
        return {
            template: require('$views/admin/piggyList.html').default,
            restrict: 'E',
            scope:{
                data: "=",
                type: "=",
                removePiggy: "&",
                addChange: "&",
                siteId: "="
            },
            link: function postLink($scope, element, attrs) {
              if( attrs.options ) {
                $scope.options = attrs.options.split(',')
              } else {
                $scope.options = ['js', 'img'];
              }

              $scope.secureOnly = attrs.secureOnly ? attrs.secureOnly : false;
            }
        };
    });

