/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
  .directive('customCheckbox', function () {
    return {
      template: '<label><div class="custom-checkbox"></div><span ng-transclude></span></label>',
      restrict: 'E',
      require: 'ngModel',
      transclude: true,
      link: function postLink($scope, element, attrs, ngModel) {
        var checkbox = element.find('.custom-checkbox');

        // model -> ui
        ngModel.$render = function(){
          checkbox.toggleClass('checked', Boolean(ngModel.$modelValue));
        }

        // ui -> model
        element.on('click', function(){
          $scope.$apply(function(){
            ngModel.$setViewValue(!checkbox.hasClass('checked'));
            ngModel.$render();
          });
        });
      }
    };
  });

