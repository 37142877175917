/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .directive('errorMessages', function () {
        return {
            template: require('$views/errMsg.html').default,
            restrict: 'A',
            scope:{
                arr: "=",
            },
            link: function postLink($scope, element) {
            }
        };
    });

