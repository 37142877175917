/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
  .directive('editable', function () {
    var editorTemplate = '<span class="click-to-edit col-sm-6">' +
      '<span ng-hide="view.editorEnabled">' +
      '<span ng-click="enableEditor($event)">{{value}}</span>' +
      '</span>' +
      '<span ng-show="view.editorEnabled">' +
      '<input maxlength="{{maxlength}}" ng-model="view.editableValue" ng-click="blockAccordion($event)">' +
      '<button ng-click="save($event)" ng-disabled="view.editableValue.length === 0" style="margin:0 10px" class=tag-white>Save</button>' +
      '<button ng-click="disableEditor($event)" class=tag-white>Cancel</button>' +
      '</span>' +
      '</span>';
    return   {
      restrict: "A",
      replace: true,
      template: editorTemplate,
      scope: {
      value: "=editable",
      callback: '&',
      maxlength: '=',
      siteId: '='
    },
    controller: function($scope) {
      $scope.view = {
        editableValue: $scope.value,
        editorEnabled: false
      };
      $scope.blockAccordion = function(event){
        event.stopPropagation();
        event.preventDefault();
      };
      $scope.enableEditor = function(event) {
        $scope.view.editorEnabled = true;
        $scope.view.editableValue = $scope.value;
        $scope.blockAccordion(event);
      };

      $scope.disableEditor = function(event) {
        $scope.blockAccordion(event);
        $scope.view.editorEnabled = false;
      };

      $scope.save = function(event) {
        $scope.value = $scope.view.editableValue;
        $scope.disableEditor(event);
        $scope.callback({name:$scope.value, id:$scope.siteId});
      };
    }
  };
  });

